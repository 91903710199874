@import "../../typography.css";
@import "../../mixins.css";

.component {
  height: 100%;
  :global .rst__moveHandle {
    background-color: transparent;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0MiIgaGVpZ2h0PSI0MiI+PGcgc3Ryb2tlPSIjMjMyMzIzIiBzdHJva2Utd2lkdGg9IjEiID48cGF0aCBkPSJNMTQgMTUuN2gxNC40Ii8+PHBhdGggZD0iTTE0IDIxLjRoMTQuNCIvPjxwYXRoIGQ9Ik0xNCAyNy4xaDE0LjQiLz48L2c+DQo8L3N2Zz4=");
    box-shadow: none;
    border: none;
    border-radius: 0;
  }

  :global .rst__rowWrapper {
    @mixin background_primary;
    border: none;
    box-shadow: 0 1px 9px 0 rgba(158, 157, 157, .06);
    padding: 0;
    height: calc(100% - 10px);
    margin: 5px 0;
  }

  :global .rst__rowContents {
    box-shadow: none;
    border: none;
    border-radius: 0;
  }

  :global .rst__rowLabel {
    width: 100%;
    padding-right: 10px;
  }

  :global .rst__rowTitle {
    @mixin metaDescriptionText;
    @mixin text_secondary;
  }

  :global .rst__lineChildren::after {
    @mixin background_primary__disabled;
  }

  :global .rst__expandButton {
    box-shadow: none;
    @mixin border_primary;
  }

  :global .rst__collapseButton {
    box-shadow: none;
    @mixin border_primary;
  }

  :global .rst__lineHalfHorizontalRight::before {
    @mixin background_primary__disabled;
  }

  :global .rst__lineFullVertical::after {
    @mixin background_primary__disabled;
  }

  :global .rst__lineHalfVerticalTop::after {
    @mixin background_primary__disabled;
  }

  :global .rst__lineHalfVerticalBottom::after {
    @mixin background_primary__disabled;
  }

  :global .rst__rowLandingPad {
    @mixin background_brand;
    &::before {
     border: none;
     @mixin background_brand;
    }
  }

  :global .rst__rowCancelPad {
    @mixin background_error;
    &::before {
     border: none;
     @mixin background_error;
    }
  }

  :global .rst__highlightTopLeftCorner {
    display: none;
  }

  :global .rst__highlightLineVertical {
    display: none;
  }

  :global .rst__highlightBottomLeftCorner {
    display: none;
  }
}

.dot {
  height: 14px;
  width: 14px;
  display: inline-block;
  margin-right: 10px;
  border-radius: 100%;
  @mixin background_primary__disabled;
  &.active {
    @mixin background_success__hover;
  }
}

.row {
  display: flex;
  width: 100%;
  line-height: 40px;
  align-items: center;
}

.content {
  flex-grow: 1;
  padding-right: 10px;
}

.moreOptionsButton {
  appearance: none;
  border: none;
  padding: 0;
  height: 40px;
}