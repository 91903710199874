@import "../../typography.css";
@import "../../mixins.css";

.component {
  @mixin metaDescriptionText;
  appearance: none;
  border: none;
  background-color: transparent;
  display: flex;
  width: 100%;
  justify-content: flex-start;
  @mixin text_secondary;
  height: 20px;
  padding: 0;
  margin: 10px 0;

  svg {
    height: 16px;
    width: 16px;
    @mixin text_secondary;
    g, path {
      @mixin fill_secondary;
    }
  }

  div:first-of-type {
    width: 26px;
  }

  span {
    padding-left: 16px;
  }
}