.container {
  height: calc(100vh - 160px);
  position: relative;
}

.createRootCategoryButton {
  position: absolute;
  top: 5px;
  right: 0;
}
