@import "../typography.css";
@import "../media.css";
@import "../mixins.css";

.content {
  @mixin bodyText;
  @mixin text_primary;
  padding: $spacingMd;
  @mixin background_primary;
}

.formContent {
  max-width: 600px;
  padding-top: $spacingMd;
  @mixin bodyText;
  @mixin text_primary;
  @mixin background_primary;
  .content {
    padding-top: 0;
    padding-bottom: $spacingSm;
  }
}

.title {
  @mixin subheaderText;
  @mixin text_primary;
  margin-bottom: $spacingSm;
  min-width: 600px;
  text-align: center;
}

.errorMessage {
  @mixin bodyText;
  @mixin text_error;
}

.header {
  padding: 0 $spacingLg;
}

.roundedFlexibleFullscreen {
  .content {
    @media(--smartphone) {
      height: 100%;
      padding: 0 $spacingSm $spacingSm $spacingSm;
      margin-bottom: $spacingLg;
      margin-top: $spacingHuge;
    }
  }

  .title {
    @media(--smartphone) {
      min-width: auto;
    }
  }

  .buttons {
    /* z-index matches dropdown_with_modal z-index to prevent overlap */
    z-index: 1;
    @media(--smartphone) {
      position: fixed;
      bottom: 0;
      width: 100%;
    }
  }

  .errorMessage {
    @mixin metaDescriptionText;
    text-align: center;
    @mixin text_error;
    margin-bottom: $spacingSm;
  }
}
